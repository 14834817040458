<div *ngIf="show" class="backdrop"></div>
<div *ngIf="show">
  <div class="loading-container">
    <lottie-player
      autoplay
      background="transparent"
      class="lottie"
      loop
      speed="1"
      src="assets/animations/loading.json"></lottie-player>
  </div>
</div>

